import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import { Seo } from "../components/Layout/components";
import StaticPageHeader from "components/StaticPageHeader";
import StaticPageContent from "components/StaticPageContent";

const PrivacyPolicy = ({ data }) => {
  const content = data?.privacyPolicy?.nodes?.[0]?.content;

  return (
    <Layout>
      <StaticPageHeader
        title="Polityka Prywatności"
        subTitle="Informacje podstawowe dotyczące przetwarzania danych osobowych"
      />
      <StaticPageContent content={content} />
    </Layout>
  );
};

export default PrivacyPolicy;

export const Head = () => (
  <Seo title="Polityka Prywatności - Rodzinne Resorty" description="" />
);

export const query = graphql`
  query {
    privacyPolicy: allWpPage(filter: { id: { eq: "cG9zdDoxNg==" } }) {
      nodes {
        id
        content
        title
      }
    }
  }
`;
