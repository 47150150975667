import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import StaticPageHeader from "components/StaticPageHeader";
import StaticPageContent from "components/StaticPageContent";
import { Seo } from "../components/Layout/components";

const CookiePolicy = ({ data }) => {
  const content = data?.privacyPolicy?.nodes?.[0]?.content;

  return (
    <Layout>
      <StaticPageHeader
        title="Polityka Plików Cookies"
        text="Niniejsza Polityka dotycząca Ciasteczek odnosi się do strony rodzinneresorty.pl. Przez używanie serwisu rodzinneresorty.pl wyrażasz zgodę na używanie ciasteczek zgodnie z tą Polityką Ciasteczek. Jeżeli nie zgadzasz się na używanie przez nas ciasteczek, powinieneś zmienić ustawienia swojej przeglądarki w odpowiedni sposób lub zrezygnować z używania strony rodzinneresorty.pl."
      />
      <StaticPageContent content={content} />
    </Layout>
  );
};

export default CookiePolicy;

export const Head = () => (
  <Seo title="Polityka Plików Cookies - Rodzinne Resorty" description="" />
);

export const query = graphql`
  query {
    privacyPolicy: allWpPage(filter: { id: { eq: "cG9zdDoxMQ==" } }) {
      nodes {
        id
        content
        title
      }
    }
  }
`;
