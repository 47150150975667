import React from "react";

import Layout from "components/Layout";
import InfoPage from "components/InfoPage";
import { Seo } from "../components/Layout/components";

const NotFound = () => {
  return (
    <Layout>
      <InfoPage title="Błąd 404" text="Ta strona nie istnieje" />
    </Layout>
  );
};

export const Head = () => <Seo title="404 - Rodzinne Resorty" description="" />;

export default NotFound;
