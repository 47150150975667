import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

import SectionHeader from "components/SectionHeader";
import { StageHeader } from "./components";

import KyriadInvestImage from "images/kyriad-invest-ads.png";

const mainClass = "home-investment";

const first_list = [
  {
    image: require("images/investment/investment-image-1.jpg").default,
    title: "Park Wodny",
    description:
      "Największy Park Wodny w okolicy – prawdziwy raj dla miłośników wodnych przygód i relaksu! Nasz park wodny rozciąga się na ponad 600 m² i oferuje niezliczone możliwości zabawy, odprężenia i odnalezienia wewnętrznego spokoju z widokiem na panoramę Karkonoszy.",
  },
  {
    image: require("images/investment/investment-image-2.jpg").default,
    title: "Chata Grillowa",
    description:
      "Chata z górskich bali, z tradycyjnym paleniskiem do organizowania imprez plenerowych w wyjątkowym klimacie.",
  },
  {
    image: require("images/investment/investment-image-3.jpg").default,
    title: "Sauny",
    description:
      "Do dyspozycji właścicieli są również 3 rodzaje saun: sauna infrared, sauna turecka, sauna fińska.",
  },
  {
    image: require("images/investment/investment-image-4.jpg").default,
    title: "Restauracja Silver Chair",
    description:
      "to prawdziwa podróż przez smaki regionu, oferujemy szeroki wybór potraw, w których dominują lokalne składniki i tradycyjne przepisy. Nasz zespół kucharzy z pasją przygotowuje dania, które podkreślają autentyczność smaków, od zup po dania główne i desery.",
  },
  {
    image: require("images/investment/investment-image-5.jpg").default,
    title: "Parking",
    description:
      "Na terenie inwestycji znajduje się monitorowany parking, stacja ładowania samochodów elektrycznych oraz zamykany parking dla motocykli.",
  },
];

const second_list = [
  {
    image: require("images/investment/investment-image-6.jpg").default,
    description:
      "Planujemy rozbudowę obecnego parku wodnego o zjeżdżalnię oraz zupełnie nową, zewnętrzna strefę wodną dla dzieci, która będzie gwarantować mnóstwo radości i przyjemnego ochłodzenia podczas ciepłych dni. Przygotujemy także wyjątkowy plac zabaw.",
  },
  {
    image: require("images/investment/investment-image-7.jpg").default,
    description:
      "Powstanie druga restauracja z nowym konceptem. Zaplanowana została również dodatkowa przestrzeń konferencyjna wyposażona w nowoczesną  technologię, która przystosowana będzie do przyjęcia jeszcze większej ilości grup oraz organizację niezapomnianych imprez oraz eventów.",
  },
  {
    image: require("images/investment/investment-image-8.jpg").default,
    description:
      "Ponadto, zaoferujemy Państwu parterowe apartamenty z ogródkami otoczonymi bujną zielenią, które będą zapewniać idealne miejsce do relaksu i organizacji wydarzeń na świeżym powietrzu, oferując zapierające dech w piersiach widoki na Karkonosze.",
  },
  {
    image: require("images/investment/investment-image-9.jpg").default,
    description:
      "Pokoje na wyższych kondygnacjach zapewnią oszałamiające wschody i zachody słońca w towarzystwie przepięknych szczytów Karkonoskiego Parku Narodowego widocznych z przeszklonych tarasów naszych komfortowych apartamentów.",
  },
  {
    image: require("images/investment/investment-image-10.jpg").default,
    title: "Parking",
    description:
      "Rozbudowany zostanie także parking dla gości oraz dodane będą kolejne stacje do ładowania samochodów elektrycznych, aby zapewnić lepszą dostępność i wygodę dla wszystkich odwiedzających nasz obiekt.",
  },
];

const AboutInvestment = () => {
  return (
    <section id="o-inwestycji-section" className={mainClass}>
      <div className="container">
        <SectionHeader title="O inwestycji" />
        <div className={`${mainClass}__group`}>
          <div
            id="kyriad-karkonosze-section"
            className={`${mainClass}__anchor`}
          />
          <StageHeader
            title="Kyriad Karkonosze"
            subtitle="1 etap - 3 ostatnie apartamenty"
            description="<p><strong>Kyriad Karkonosze</strong> to wyjątkowy projekt condohotelowy u podnóża Karkonoszy, znajdujący się w perfekcyjnej, skąpanej zielenią lokalizacji, zaledwie 10 min drogi od Szklarskiej Poręby.</p>"
          />
          <img
            className={`${mainClass}__kyriad-baner`}
            src={KyriadInvestImage}
            alt=""
          />
          <div className={`${mainClass}__features`}>
            <div>
              <p>
                Oferujemy rozbudowaną infrastrukturę hotelową, jeden z
                największych parków wodnych z atrakcjami dla dzieci, saunami i
                jacuzzi w regionie, świetną lokalną kuchnię, sale konferencyjne
                oraz siłownię.
              </p>
            </div>
            <div>
              <p>
                Obiekt należy do jednej z największych sieci hotelowych na
                świecie - Louvre Hotels Group, właściciela marki Kyriad - co
                stanowi o mocnych fundamentach biznesowych projektu
                inwestycyjnego.
              </p>
            </div>
          </div>
          <div className={`${mainClass}__announcement`}>
            <div className={`${mainClass}__announcement-list`}>
              {first_list.map((item, index) => (
                <div
                  className={`${mainClass}__announcement-list-item`}
                  key={index}
                >
                  <div
                    className={`${mainClass}__announcement-list-image`}
                    style={{ backgroundImage: `url(${item.image})` }}
                  />
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={`${mainClass}__group`}>
          <div id="kaizen-section" className={`${mainClass}__anchor`} />
          <StageHeader
            title="KaiZEN"
            subtitle="2 etap - już w sprzedaży!"
            notice="<p>Z radością ogłaszamy rozpoczęcie budowy <span>drugiego etapu</span> naszego autorskiego przedsięwzięcia condohotelowego!</p>"
            description="<p><strong>KaiZEN</strong>, bo tak nazwaliśmy naszą inwestycję, oferować będzie apartamenty z widokiem na panoramę Karkonoszy w czterogwiazdkowym standardzie a także jeszcze więcej udogodnień i atrakcji dla przyszłych właścicieli i gości.</p>"
          />
          <div className={`${mainClass}__announcement`}>
            <div className={`${mainClass}__announcement-list`}>
              {second_list.map((item, index) => (
                <div
                  className={`${mainClass}__announcement-list-item`}
                  key={index}
                >
                  <div
                    className={`${mainClass}__announcement-list-image`}
                    style={{ backgroundImage: `url(${item.image})` }}
                  />
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
            <div className={`${mainClass}__announcement-footer`}>
              <p>
                <strong>KaiZEN</strong> to miejsce, które zapewni Państwu nie
                tylko spokój i wytchnienie w otoczeniu natury, ale również
                gwarantuje doskonałą inwestycję na przyszłość.
              </p>
              <h4>
                Stań się właścicielem jednego
                <br />z naszych wyjątkowych apartamentów!{" "}
                <Link to="/#kontaktform2">Wypełnij formularz</Link> i poczekaj
                na kontakt naszego Doradcy!
              </h4>
            </div>
          </div>
        </div>
        {/* <div className={`${mainClass}__content`}>
			<div className={`${mainClass}__content-left`}>
				<img src={RoomImage} alt="" />
				<div className={`${mainClass}__content-left-caption`}>
					<h3>
						Już od 13 200zł netto/m<sup>2</sup>
					</h3>
				</div>
			</div>
			<div className={`${mainClass}__content-right`}>
				<img src={AquaParkImage} alt="" />
				<div className={`${mainClass}__content-right-caption`}>
					<h3>Aquapark</h3>
					<CircleIcon icon={<SwimingPoolIcon />} />
				</div>
			</div>
		</div> */}
      </div>
    </section>
  );
};

export default AboutInvestment;
