import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import { Seo } from "../components/Layout/components";
import {
  Hero,
  AboutInvestment,
  BusinessModel,
  Facilities,
  Aquapark,
  Calculator,
  Apartments,
  Location,
  Foundations,
  BuyProcess,
  Gallery,
} from "page_components/home";

import scrollToElement from "utils/scrollToElement";

const Home = ({ location, data }) => {
  const { hash, pathname } = location;
  const [init_loading, setInitLoading] = useState(false);

  useEffect(() => {
    setInitLoading(true);
  }, [hash]);

  useEffect(() => {
    if (!!!hash) return;

    if (!!init_loading) {
      scrollToElement(`${hash.split("?")[0]}-section`);
    } else {
      setTimeout(() => {
        scrollToElement(`${hash.split("?")[0]}-section`);
      }, 100);
    }

    setInitLoading(true);
  }, [location]);

  return (
    <Layout
      seo={{
        title:
          "Rodzinne Resorty - inwestycje condohotelowe w najlepszych lokalizacjach",
        description: "",
      }}
      location={{ pathname, hash }}
      apartments={data?.allApartments?.nodes}
      isHome
    >
      <Hero />
      <AboutInvestment />
      <BusinessModel />
      <BuyProcess />
      <Calculator
        apartments={{
          stage_1: data?.allApartments?.nodes.filter(
            (node) =>
              node.nieruchomosci.investmentName === "Kyriad Karkonosze" &&
              node.nieruchomosci.statusId === "1"
          ),
          stage_2: data?.allApartments?.nodes.filter(
            (node) =>
              node.nieruchomosci.investmentName === "Kyriad 2" &&
              node.nieruchomosci.statusId === "1"
          ),
        }}
      />
      <Apartments
        data={{
          stage_1: data?.allApartments?.nodes.filter(
            (node) => node.nieruchomosci.investmentName === "Kyriad Karkonosze"
          ),
          stage_2: data?.allApartments?.nodes.filter(
            (node) => node.nieruchomosci.investmentName === "Kyriad 2"
          ),
        }}
      />
      <Location />
      <Facilities />
      <Aquapark />
      <Gallery />
      <Foundations />
    </Layout>
  );
};

export default Home;

export const Head = () => (
  <Seo
    title="Rodzinne Resorty - inwestycje condohotelowe w najlepszych lokalizacjach"
    description=""
  />
);

export const query = graphql`
  query {
    allApartments: allWpNieruchomosc {
      nodes {
        id
        title
        nieruchomosci {
          investmentName
          nameBuilding
          floor
          area
          rooms
          statusId
          cardLink
          photoLink1
          idCrm
        }
      }
    }
  }
`;
