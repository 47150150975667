import React from "react";

import Layout from "components/Layout";
import InfoPage from "components/InfoPage";
import { Seo } from "../components/Layout/components";

const ThankYou = () => {
  return (
    <Layout>
      <InfoPage title="Dziękujemy" text="za wysłanie formularza" />
    </Layout>
  );
};

export const Head = () => (
  <Seo title="Dziękujemy - Rodzinne Resorty" description="" />
);

export default ThankYou;
